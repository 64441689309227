import { createReducer } from '@reduxjs/toolkit'
import { MyNFTType } from 'hooks/setGetListMyNft'

import { setListNFT, setMyNft } from './actions'
import { NFTItemType } from './type'

export interface WalletState {
  myNft: MyNFTType[]
  listNFT: NFTItemType[]
}

export const initialState: WalletState = {
  myNft: [],
  listNFT: [],
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setMyNft, (state, action) => {
      state.myNft = action.payload
    })
    .addCase(setListNFT, (state, action) => {
      return {
        ...state,
        listNFT: action?.payload,
      }
    }),
)
