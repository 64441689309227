import { BASE_API_LIVE_SWAP } from 'config/constants/endpoints'
import fetchHelper from 'helpers/FetchHelper'

function getListMyNft(): Promise<MyNFTType[]> {
  return new Promise<any>((resolve, reject) => {
    try {
      fetchHelper
        .fetch(`${BASE_API_LIVE_SWAP}/nft-stake/my-nft`, {
          method: 'GET',
        })
        .then(([resp, status]: any) => {
          if (status === 200) {
            resolve(resp?.data)
          }

          throw resp
        })
    } catch (error) {
      reject(error)
    }
  })
}

export default getListMyNft

export interface MyNFTType {
  remainBonus: number
  isCompleted: boolean
  _id: string
  updatedAt: Date
  createdBy?: Date
  userId: string
  nftId: NftID
  price: number
  isStaked: boolean
  createdAt: Date
  stakePlanId: StakePlanID
  id: string
}

export interface NftID {
  _id: string
  title: string
  price: number
  description: string
  isPublic: boolean
  image: string
  apr: number
  bonus20: number
  bonus80: number
  dailyEarn: number
  profit: number
  voucherOMediaDiscount: number
  id: string
}

export interface StakePlanID {
  _id: string
  quantity: number
  isBoost: boolean
  status: StatusType
  startDate: number
  endDate: number
  id: string
}

type StatusType = 'COMPLETED' | 'RUNNING' | 'UNSTAKED'
