/* eslint-disable jsx-a11y/anchor-is-valid */
import LocalizeSelector from 'components/LocalizeSelector'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import { Flex, Link } from 'widgets'
import { DiscordIcon, TelegramIcon, TwitterIcon, YoutubeIcon } from '../../../packages/uikit/src/components/Svg'

const FooterWrapper = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 48px 0;
  border-top: 1px solid rgba(250, 255, 255, 0.1);

  .container {
    width: 100%;
    max-width: 1024px;
  }

  img {
    width: 55px;
  }

  .dot {
    display: none;

    ${({ theme }) => theme.mediaQueries.md} {
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background: #faffff;
      margin: 10px 48px;
    }
  }

  .country-selector ul {
    top: -12px;
    transform: translateY(-100%);
  }

  .lang-selector {
    width: 100%;

    ${({ theme }) => theme.mediaQueries.md} {
      width: fit-content;
      min-width: 170px;
    }

    > div {
      width: 100%;

      ${({ theme }) => theme.mediaQueries.md} {
        width: 170px;
      }
    }

    .country-selector ul {
      width: 100%;

      ${({ theme }) => theme.mediaQueries.md} {
        width: 170px;
      }
    }
  }
`

const Community = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;

  ${({ theme }) => theme.mediaQueries.md} {
    margin: 0;
  }

  > p {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5;

    ${({ theme }) => theme.mediaQueries.md} {
      font-size: 22px;
    }
  }

  > a {
    display: block;
    margin-left: 16px;
    width: 32px;
    height: 32px;

    ${({ theme }) => theme.mediaQueries.md} {
      margin-left: 24px;
    }
  }
`

const Footer = () => {
  const { t } = useTranslation()

  return (
    <FooterWrapper>
      <Flex className="container" flexDirection="column" alignItems="center">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          flexDirection={['column', null, null, 'row']}
        >
          <Link href="/">
            <img src="/images/logo.png" alt="" />
          </Link>

          <Community>
            <p>{t('Community')}</p>
            <Link href="https://discord.gg/DnbK7Cwd" external>
              <DiscordIcon />
            </Link>
            <Link href="https://t.me/xfiglobal" external>
              <TelegramIcon />
            </Link>
            <Link href="https://twitter.com/nftxfi" external>
              <TwitterIcon />
            </Link>
            {/* <Link href="#" external>
              <YoutubeIcon />
            </Link> */}
          </Community>

          <LocalizeSelector className="lang-selector" />
        </Flex>

        <Flex alignItems="center" mt="24px" flexDirection={['column', null, null, 'row']}>
          <p style={{ textAlign: 'center' }}>{t('Copyright 2023 XFI. All rights reserved')}</p>
          <p className="dot" />
          <p style={{ textAlign: 'center' }}>{t('We use cookies for better services')}</p>
        </Flex>
      </Flex>
    </FooterWrapper>
  )
}

export default Footer
