import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M21.3103 6.87844L17.1216 2.68875C16.9823 2.54943 16.8169 2.43891 16.6349 2.3635C16.4529 2.2881 16.2578 2.24929 16.0608 2.24929C15.8638 2.24929 15.6687 2.2881 15.4867 2.3635C15.3047 2.43891 15.1393 2.54943 15 2.68875L3.43969 14.25C3.2998 14.3888 3.18889 14.554 3.11341 14.736C3.03792 14.918 2.99938 15.1133 3.00001 15.3103V19.5C3.00001 19.8978 3.15804 20.2794 3.43935 20.5607C3.72065 20.842 4.10218 21 4.50001 21H8.6897C8.88675 21.0006 9.08197 20.9621 9.26399 20.8866C9.44602 20.8111 9.61122 20.7002 9.75001 20.5603L21.3103 9C21.4496 8.86071 21.5602 8.69534 21.6356 8.51332C21.711 8.33131 21.7498 8.13623 21.7498 7.93922C21.7498 7.74221 21.711 7.54713 21.6356 7.36512C21.5602 7.18311 21.4496 7.01773 21.3103 6.87844ZM8.6897 19.5H4.50001V15.3103L12.75 7.06031L16.9397 11.25L8.6897 19.5ZM18 10.1888L13.8103 6L16.0603 3.75L20.25 7.93875L18 10.1888Z"
        fill="#989996"
      />
    </Svg>
  )
}

export default Icon
