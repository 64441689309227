import { useSocketContext } from 'contexts/SocketContext'
import fetchHelper, { getAuthToken } from 'helpers/FetchHelper'

export const useIsLogin = () => {
  const authToken = getAuthToken()
  const { updateAuthToken } = useSocketContext()
  if (authToken) {
    fetchHelper.addDefaultHeader('Authorization', `Bearer ${authToken}`)
    updateAuthToken(authToken)
  }
  return Boolean(authToken)
}
