import { ChainId, JSBI, Percent, Token } from '@pancakeswap/sdk'
import { BigNumber } from '@ethersproject/bignumber'
import { mainnetTokens, testnetTokens } from './tokens'

export * from './localize'

export const ROUTER_ADDRESS = {
  [ChainId.MAINNET]: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  [ChainId.TESTNET]: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
}

/**
 * Cache at locale global
 */
export const CACHE_TOKEN = 'xfi'
export const CACHE_REFRESH_TOKEN = 'xfi_refresh'
export const CACHE_USER_INFO = 'xfi_userInfo'
export const CACHE_REF_TOKEN = 'xfi_ref'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.MAINNET]: [
    mainnetTokens.wbnb,
    mainnetTokens.cake,
    mainnetTokens.busd,
    mainnetTokens.usdt,
    mainnetTokens.btcb,
    mainnetTokens.ust,
    mainnetTokens.eth,
    mainnetTokens.usdc,
  ],
  [ChainId.TESTNET]: [testnetTokens.wbnb, testnetTokens.cake, testnetTokens.busd],
}

/**
 * Addittional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WETH[ChainId.MAINNET]]
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.MAINNET]: [mainnetTokens.busd, mainnetTokens.cake, mainnetTokens.btcb],
  [ChainId.TESTNET]: [testnetTokens.wbnb, testnetTokens.cake, testnetTokens.busd],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.MAINNET]: [mainnetTokens.wbnb, mainnetTokens.dai, mainnetTokens.busd, mainnetTokens.usdt],
  [ChainId.TESTNET]: [testnetTokens.wbnb, testnetTokens.cake, testnetTokens.busd],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [mainnetTokens.cake, mainnetTokens.wbnb],
    [mainnetTokens.busd, mainnetTokens.usdt],
    [mainnetTokens.dai, mainnetTokens.usdt],
  ],
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 500
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 BNB
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

// Root doamin
export const ROOT_DOMAIN = process.env.NEXT_PUBLIC_DOMAIN as string

export const ROOT_EMAIL = 'khanhphamvt1991@gmail.com'

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
  '0x8576aCC5C05D6Ce88f4e49bf65BdF0C62F91353C',
]

export { default as farmsConfig } from './farms'
export { default as poolsConfig } from './pools'
export { default as ifosConfig } from './ifo'

export const FAST_INTERVAL = 10000
export const SLOW_INTERVAL = 60000

export const NOT_ON_SALE_SELLER = '0x0000000000000000000000000000000000000000'

// Gelato uses this address to define a native currency in all chains
export const GELATO_NATIVE = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
// Handler string is passed to Gelato to use PCS router
export const GELATO_HANDLER = 'pancakeswap'
export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = BigNumber.from(500000)

export const EXCHANGE_DOCS_URLS = 'https://docs.pancakeswap.finance/products/pancakeswap-exchange'
export const LIMIT_ORDERS_DOCS_URL = 'https://docs.pancakeswap.finance/products/pancakeswap-exchange/limit-orders'

export const CHART_COLORS = ['#01FFA3', '#0AFAEB', '#0165FC', '#6D40F0', '#FF8AF3', '#151A20']

export const ROOT_API = process.env.NEXT_PUBLIC_API as string
export const X_API_KEY = process.env.NEXT_PUBLIC_X_API_KEY as string

export const DOMAIN_NETWORK = process.env.NEXT_PUBLIC_DOMAIN_NETWORK as string

export const SFAddresses = [
  '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE'.toLowerCase(),
  '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16'.toLowerCase(),
  '0x9628409bEFf7EaC7443CC1c42b719c08266aB40A'.toLowerCase(),
  '0x567D58aA8fF12Cddf87a082b4f174c37D53cDB74'.toLowerCase(), // poodlefi
]
export const actionNames = {
  BUY_NFT: 'Buy NFT',
  COMMISSION_BUY_NFT: 'Commission',
  COMMISSION_STAKE_X2: 'Commission',
  REWARD: 'Reward',
  DAILY_PROFIT: 'Daily profit',
  DEPOSIT: 'Deposit',
  INTERNAL_TRANSFER: 'Internal transfer',
  STAKE: 'Stake',
  STAKEX2: 'Stake',
  UNSTAKE: 'Unstake',
  WITHDRAW_COMMISSION: 'Withdraw commission',
  WITHDRAW: 'Withdraw',
  AIRDROP: 'Airdrop',
}

export const actionIcons = {
  BUY_NFT: '/icons/image.svg',
  COMMISSION_BUY_NFT: '/icons/commission.svg',
  REWARD: '/icons/commission.svg',
  DAILY_PROFIT: '/icons/commission.svg',
  COMMISSION_STAKE_X2: '/icons/commission.svg',
  DEPOSIT: '/icons/plus-circle.svg',
  STAKE: '/icons/stake.svg',
  STAKEX2: '/icons/stake.svg',
  UNSTAKE: '/icons/unstake.svg',
  WITHDRAW_COMMISSION: '/icons/minus-circle.svg',
  WITHDRAW: '/icons/minus-circle.svg',
  AIRDROP: '/icons/commission.svg',
}

export const rankImages = {
  0: 'images/badge/bronze_square.png',
  1: 'images/badge/gold_square.png',
  2: 'images/badge/titalium_square.png',
  3: 'images/badge/diamond_square.png',
}

export const API_ASDI_CMS_BASE_URL = process.env.NEXT_PUBLIC_API_ASDI_CMS_BASE_URL as string

// Github
export const ENV_GIT_PRIVATE_KEY_GITHUB = process.env.NEXT_PUBLIC_GITHUB_PRIVATE_KEY as string
export const ENV_GIT_ORGANIZATION = process.env.NEXT_PUBLIC_ORGANIZATION as string
export const ENV_GIT_REPOS = process.env.NEXT_PUBLIC_REPOS as string
export const ENV_GIT_TOKEN_LIST_FILENAME = process.env.NEXT_PUBLIC_TOKEN_LIST_FILENAME as string
export const ENV_GIT_ROOT_FOLDER = process.env.NEXT_PUBLIC_ROOT_FOLDER as string

export const AUTH_ERROR_CODE = {
  EMAIL_REGISTERED: 'The Email was registered',
  EMAIL_CODE_INVALID: 'Your OTP is not correct',
  USER_ACTIVATED: 'Account has been activated',
  REF_NOT_FOUND: 'Referral code not found',
}

export const DEFAULT_AVATAR = 'images/logo.png'
export const DEFAULT_STAKING_DAY = 180 * 24 * 60 * 60

export const TOKEN_WITHDRAW = {
  USDT: 'USDT',
  PROFIT: 'USDT',
  OPV: 'OPV',
}

export const TOKEN_ICON_MAPPING = {
  USDT: '/images/swap/USDT.svg',
  PROFIT: '/images/swap/USDT.svg',
  OPV: '/images/swap/OPV.png',
}

export const WALLET_NAME = {
  market_develop: 'Market Wallet',
  '80_percent': 'Revenue',
  profit: 'Capital Wallet',
  opv_balance: 'Fund OPV',
  reward: 'Reward Wallet',
}

export const ADMIN_WALLET = {
  MARKET_DEVELOP: 'market_develop',
  EIGHTY_PERCENT: '80_percent',
  OPV_BALANCE: 'opv_balance',
  PROFIT: 'profit',
  REWARD: 'reward',
}

export const USER_ROLE = {
  ADMIN: 'admin',
  ADMIN_2: 'admin2',
  USER: 'user',
}

export const DATE_FORMAT = {
  DATE: 'MM/DD/YYYY',
  DATE_TIME: 'MM/DD/YYYY - HH:mm',
}
