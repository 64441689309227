import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="33" height="32" viewBox="0 0 33 32" fill="none" {...props}>
      <path
        d="M26.2291 8.34416C26.1597 8.14211 26.0771 8.08554 25.949 8.03704C25.669 7.92928 25.1943 8.09092 25.1943 8.09092C25.1943 8.09092 8.36848 14.1983 7.40838 14.8745C7.20035 15.02 7.13101 15.1035 7.09901 15.2032C6.93366 15.6854 7.45105 15.8982 7.45105 15.8982L11.7875 17.3261C11.7875 17.3261 11.9502 17.3503 12.0062 17.3126C12.993 16.6822 21.9326 10.9816 22.4473 10.7903C22.53 10.7661 22.5887 10.793 22.5727 10.8496C22.3673 11.5824 14.6038 18.5519 14.6038 18.5519C14.6038 18.5519 14.5745 18.5896 14.5558 18.6327L14.5451 18.6273L14.1398 22.9728C14.1398 22.9728 13.9691 24.3036 15.2892 22.9728C16.22 22.0326 17.1188 21.2486 17.5695 20.866C19.0603 21.9059 20.6658 23.0563 21.3565 23.6571C21.7032 23.9588 21.9966 24.0073 22.234 23.9992C22.89 23.9749 23.0741 23.2449 23.0741 23.2449C23.0741 23.2449 26.1411 10.7796 26.2424 9.10927C26.2531 8.94493 26.2664 8.84256 26.2664 8.72941C26.2664 8.57316 26.2531 8.4169 26.2291 8.34147V8.34416Z"
        fill="#FAFFFF"
      />
    </Svg>
  );
};

export default Icon;
