import { Button } from 'antd'
import { useTranslation } from 'contexts/Localization'
import buyNft from 'hooks/setGetBuyNft'
import getListMyNft from 'hooks/setGetListMyNft'
import useToast from 'hooks/useToast'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setModalBuyNow } from 'state/modal/actions'
import { setMyNft } from 'state/nfts/actions'
import styled from 'styled-components'
import BaseModalStyle from '../BaseModalStyle'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;
  width: 100%;
  border: 2px solid rgba(173, 247, 0, 0.1);
  background: rgba(253, 255, 250, 0.05);
  backdrop-filter: blur(30px);

  .nft-item-image {
    position: relative;
    padding-top: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .nft-item-info {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .nft-item-info-name {
      p {
        color: #adf700;
        font-size: 14px;
        line-height: 24px;
        font-style: normal;
        font-weight: 700;
      }
    }

    .nft-item-info-price {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .nft-item-info-price-currency {
        display: flex;
        align-items: center;

        img {
          height: 24px;
          width: 24px;
          margin-right: 8px;
        }

        p {
          font-weight: 400;
        }
      }

      p {
        color: #fdfffa;
        font-size: 18px;
        line-height: 24px;
        font-style: normal;
        font-weight: 700;
      }
    }
  }

  .nft-item-action {
    display: flex;
    align-items: center;

    button {
      width: 100%;
      padding: 8px;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
`

const WrapperSuccess = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  text-align: center;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.lg} {
    row-gap: 24px;
  }

  img {
    width: 120px;
    max-width: 100%;
  }

  button {
    padding: 8px 15px;
    width: 100%;
  }
`

const ModalBuyNow = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { toastError, toastSuccess } = useToast()
  const { modalBuyNow } = useSelector((state: any) => state.modal)

  const [view, setView] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const handleOk = () => {
    setLoading(true)

    buyNft(
      {
        nftId: modalBuyNow?.data?.id,
      },
      () => {
        setView(1)
        setLoading(false)
        toastSuccess(t('you have successfully bought NFT'))
        getListMyNft().then((data) => {
          dispatch(setMyNft(data?.sort((a, b) => a.price - b.price)))
        })
      },
      () => {
        setLoading(false)
        toastError(t('You have failed to buy a NFT'))
      },
    )
  }

  const handleCancel = () => {
    if (!loading) {
      setView(0)
      dispatch(setModalBuyNow({ toggle: false, data: {} }))
    }
  }

  return (
    <BaseModalStyle
      title={view === 0 ? t('Purchase this NFT?') : ''}
      visible={modalBuyNow?.toggle}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      {view === 0 ? (
        <Wrapper>
          <div className="nft-item-image">
            <img src={modalBuyNow?.data?.image} alt={modalBuyNow?.data?.title} />
          </div>

          <div className="nft-item-info">
            <div className="nft-item-info-name">
              <p>{modalBuyNow?.data?.title}</p>
            </div>
            <div className="nft-item-info-price">
              <div className="nft-item-info-price-currency">
                <img src="/images/icons/USDT.png" alt="" />
                <p>USDT</p>
              </div>
              <p>{modalBuyNow?.data?.price}</p>
            </div>
          </div>

          <div className="nft-item-action">
            <Button onClick={handleCancel}>{t('Cancel')}</Button>
            <Button onClick={handleOk} className="active">
              {t('Buy')}
            </Button>
          </div>
        </Wrapper>
      ) : (
        <WrapperSuccess>
          <img src="/images/icons/checked.png" alt="" />
          <p>{t('Buy NFT successfully')}</p>
          <Button onClick={handleCancel} className="active">
            {t('Close')}
          </Button>
        </WrapperSuccess>
      )}
    </BaseModalStyle>
  )
}

export default ModalBuyNow
