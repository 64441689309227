import { BASE_API_LIVE_SWAP } from 'config/constants/endpoints'
import fetchHelper from 'helpers/FetchHelper'

function getMyBalance(payload?: any, cbs?: any, cbe?: any) {
  try {
    fetchHelper
      .fetch(`${BASE_API_LIVE_SWAP}/wallet/my-balance`, {
        method: 'GET',
      })
      .then(([resp, status]: any) => {
        if (status === 200) {
          if (cbs) cbs(resp.data)
        } else if (cbe) cbe(resp)
      })
  } catch (error) {
    console.error('getMyBalance', error)
  }

  return null
}

export default getMyBalance
