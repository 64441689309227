import { BASE_API_LIVE_SWAP } from 'config/constants/endpoints'
import fetchHelper from 'helpers/FetchHelper'

function buyNft(payload: any, cbs?: any, cbe?: any) {
  try {
    fetchHelper
      .fetch(`${BASE_API_LIVE_SWAP}/nft-stake/buy`, {
        method: 'POST',
        body: JSON.stringify(payload),
      })
      .then(([resp, status]: any) => {
        if (status === 201) {
          if (cbs) cbs(resp.data)
        } else if (cbe) cbe(resp)
      })
  } catch (error) {
    console.error('buyNft', error)
  }

  return null
}

export default buyNft
