import { createReducer } from '@reduxjs/toolkit'
import { BalanceType, WalletType } from 'config/types/Wallet'
import { setOpvPrice, setOpvWallet, setUsdtWallet, setWalletBalance, updateWalletBalance } from './actions'

export interface WalletState {
  opvWallet: WalletType | undefined
  usdtWallet: WalletType | undefined
  balances: BalanceType[] | []
  opvPrice: number | undefined
}

export const initialState: WalletState = {
  opvWallet: undefined,
  usdtWallet: undefined,
  balances: [],
  opvPrice: undefined,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setOpvWallet, (state, action) => {
      state.opvWallet = action.payload
    })
    .addCase(setUsdtWallet, (state, action) => {
      state.usdtWallet = action.payload
    })
    .addCase(setWalletBalance, (state, action) => {
      state.balances = action.payload
    })
    .addCase(updateWalletBalance, (state, action) => {
      try {
        if (!state?.balances?.length) return state

        const tokenIndex = state?.balances?.findIndex((item) => item?.tokenSymbol === action?.payload?.token)

        if (tokenIndex < 0) {
          return state
        }

        const currentToken = state?.balances?.[tokenIndex]

        return {
          ...state,
          balances: [
            ...state?.balances?.slice(0, tokenIndex),
            {
              ...currentToken,
              available: currentToken?.available + action?.payload?.amount,
            },
            ...state?.balances?.slice(tokenIndex + 1),
          ],
        }
      } catch (err) {
        return state
      }
    })
    .addCase(setOpvPrice, (state, action) => {
      state.opvPrice = action.payload
    }),
)
