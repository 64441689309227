/* eslint-disable no-new */
/* eslint-disable dot-notation */
import { ResetCSS, useModal } from '@pancakeswap/uikit'
import 'antd/dist/antd.css'
import 'antd/dist/antd.dark.css'
import BigNumber from 'bignumber.js'
import GlobalCheckClaimStatus from 'components/GlobalCheckClaimStatus'
import FixedSubgraphHealthIndicator from 'components/SubgraphHealthIndicator'
import { ToastListener } from 'contexts/ToastsContext'
import { useAccountEventListener } from 'hooks/useAccountEventListener'
import useEagerConnect from 'hooks/useEagerConnect'
import useSentryUser from 'hooks/useSentryUser'
import useUserAgent from 'hooks/useUserAgent'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Script from 'next/script'
import { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, useStore } from 'state'
import { usePollBlockNumber } from 'state/block/hooks'
import '../style/animation.css'
import '../style/global.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// import useGetBalance from 'hooks/useGetBalance'
import { useIsLogin } from 'hooks/useIsLogin'
import PublicLayout from 'layouts/PublicLayout'
import { updateUserInfo } from 'state/user/actions'
import getMyProfile from 'state/user/hooks/profile/getMyProfile'
import { useUserData } from 'state/user/hooks/useUserData'
import { setUsdtWallet, setWalletBalance } from 'state/wallet/actions'
import { getMyBalance, getWallet } from 'state/wallet/fetch'
import ModalBuyNow from 'components/BaseModal/ModalBuyNow'
import ModalMaintain from 'components/Modal/ModalMaintain'

import { Blocklist, Updaters } from '..'
import Providers from '../Providers'
import ErrorBoundary from '../components/ErrorBoundary'
import GlobalStyle from '../style/Global'

const EasterEgg = dynamic(() => import('components/EasterEgg'), { ssr: false })

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function GlobalHooks() {
  usePollBlockNumber()
  useEagerConnect()
  useUserAgent()
  useAccountEventListener()
  useSentryUser()
  return null
}

function MyApp(props: AppProps) {
  const { pageProps } = props
  const store = useStore(pageProps.initialReduxState)

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="noodp,noydir" />

        {/* <!-- Link --> */}
        <link rel="apple-touch-icon" type="image/x-icon" href="%PUBLIC_URL%/favicon.ico?v=1.1" />
        <link rel="shortcut icon" type="image/png" href="%PUBLIC_URL%/favicon.ico?v=1.1" />

        {/* <!-- Og --> */}
        <meta property="og:title" content="XFI - STAKING ANY WHERE, ANY TIME, SECURITY & SAFE" />
        <meta property="og:description" content="XFI - STAKING ANY WHERE, ANY TIME, SECURITY & SAFE" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="XFI - STAKING ANY WHERE, ANY TIME, SECURITY & SAFE" />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="LeoExchange" />
        <meta property="og:image" content={`${process?.env?.NEXT_PUBLIC_DOMAIN}/graph.png?v=2`} />
        <meta property="og:url" content={process?.env?.NEXT_PUBLIC_DOMAIN} />

        {/* <!-- Twitter --> */}
        <meta name="twitter:title" content="XFI - STAKING ANY WHERE, ANY TIME, SECURITY & SAFE" />
        <meta name="twitter:description" content="XFI - STAKING ANY WHERE, ANY TIME, SECURITY & SAFE" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@nftxfi" />
        <meta name="twitter:image" content={`${process?.env?.NEXT_PUBLIC_DOMAIN}/graph.png?v=2`} />
        <meta name="twitter:creator" content="@nftxfi" />

        {/* <!-- Title --> */}
        <title>XFI - STAKING ANY WHERE, ANY TIME, SECURITY & SAFE</title>
        <meta name="keywords" content="XFI - STAKING ANY WHERE, ANY TIME, SECURITY & SAFE" />
        <meta name="description" content="XFI - STAKING ANY WHERE, ANY TIME, SECURITY & SAFE" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <Providers store={store}>
        <Blocklist>
          <GlobalHooks />
          <Updaters />
          <ResetCSS />
          <GlobalStyle />
          <GlobalCheckClaimStatus excludeLocations={[]} />
          <PersistGate loading={null} persistor={persistor}>
            <App {...props} />
          </PersistGate>
        </Blocklist>
      </Providers>
      {/* <Script
        strategy="afterInteractive"
        id="google-tag"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTAG}');
          `,
        }}
      /> */}
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC
  fullLayout?: boolean
  authLayout?: boolean
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? ErrorBoundary : Fragment

const App = ({ Component, pageProps, ...props }: AppPropsWithLayout) => {
  const dispatch = useDispatch()
  // const [openModal] = useModal(<ModalMaintain />)

  // useGetBalance()
  // Use the layout defined at the page level, if available
  const Layout = Fragment

  const googleTranslateElementInit = () => {
    new window['google'].translate.TranslateElement(
      {
        pageLanguage: 'en',
        autoDisplay: false,
      },
      'google_translate_element',
    )
  }

  useEffect(() => {
    const addScript = document.createElement('script')
    addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit')
    document.body.appendChild(addScript)
    window['googleTranslateElementInit'] = googleTranslateElementInit
  }, [])

  const hadValidToken = useIsLogin()

  useEffect(() => {
    // openModal()
    if (hadValidToken) {
      getMyProfile({}, (res) => {
        if (res?.isActive && !res?.isBlocked && !res?.isDeleted) {
          dispatch(updateUserInfo(res))
        }
      })

      getWallet('USDT', (res) => dispatch(setUsdtWallet(res)))
    } else {
      dispatch(updateUserInfo(undefined))
      dispatch(setUsdtWallet(undefined))
    }
  }, [dispatch, hadValidToken])

  const useData = useUserData()

  return (
    <ProductionErrorBoundary>
      {/* <TokenUpdater /> */}
      <Layout>
        {Component.fullLayout ? (
          <Component {...pageProps} />
        ) : (
          <PublicLayout isAuth={Component?.authLayout} isLogged={useData}>
            <div id="google_translate_element" />
            <Component {...pageProps} />
          </PublicLayout>
        )}
      </Layout>
      <EasterEgg iterations={2} />
      <ToastListener />
      <FixedSubgraphHealthIndicator />
      <ModalBuyNow />
    </ProductionErrorBoundary>
  )
}

export default MyApp
