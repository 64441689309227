import { TriggerBlanceType } from 'config/types/Wallet'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Socket, io } from 'socket.io-client'
import { setWalletBalance, updateWalletBalance } from 'state/wallet/actions'
import { getMyBalance } from 'state/wallet/fetch'

type EventName = string

type SocketContextType = {
  socket: Socket | null
  notifications: any
  updateAuthToken: (token: string) => void
}

const SocketContext = createContext<SocketContextType | undefined>(undefined)

const useSocket = (serverUrl: string): [Socket | null, (token: string) => void] => {
  const socketRef = useRef<Socket | null>(null)
  const [authToken, setAuthToken] = useState<string>('')
  const dispatch = useDispatch()

  const handleSocketConnected = () => {
    getMyBalance('', (res) => dispatch(setWalletBalance(res)))
  }

  const onBalanceListener = (res: TriggerBlanceType) => {
    dispatch(updateWalletBalance(res))
  }

  useEffect(() => {
    // Tạo kết nối socket khi component được mount
    const socket = io(serverUrl, {
      extraHeaders: {
        authorization: `Bearer ${authToken}`,
      },
    })
    socketRef.current = socket

    // Xử lý các sự kiện từ socket server
    socket.on('connect', () => {
      console.log('Connected to socket server')
      socketRef.current = socket
    })

    socket.on('connected', handleSocketConnected)
    socket.on('BALANCE', onBalanceListener)
    socket.on('disconnect', (error) => console.error('error', error))

    // Xử lý các tác động phụ khi component bị unmount
    return () => {
      socket.off('connected', handleSocketConnected)
      socket.off('BALANCE', onBalanceListener)

      socket.disconnect()
      socketRef.current = null
    }
  }, [serverUrl, authToken])

  const updateAuthToken = (token: string) => {
    setAuthToken(token)
  }

  return [socketRef.current, updateAuthToken]
}

const eventNames = ['deposit_event', 'withdraw_event', 'transfer_event', 'swap_event', 'add_pool', 'remove_pool']

export const SocketProvider: React.FC<{ serverUrl: string }> = ({ serverUrl, children }) => {
  const [socket, updateAuthToken] = useSocket(serverUrl)
  // const [notificationCount, setNotificationCount] = useState(0)
  const [notifications, setNotifications] = useState(
    eventNames.reduce((obj, eventName) => ({ ...obj, [eventName]: [] }), {}),
  )
  const eventListeners = useRef<{ eventName: EventName; listener: (...args: any[]) => void }[]>([])

  useEffect(() => {
    if (socket) {
      eventNames.forEach((eventName) => {
        const listener = (data: any) => {
          setNotifications((val) => {
            val[eventName].push(data)
            return { ...val }
          })
        }
        socket.on(eventName, listener)
        eventListeners.current.push({ eventName, listener })
      })
    }

    return () => {
      eventListeners.current.forEach(({ eventName, listener }) => {
        socket.off(eventName, listener)
      })
    }
  }, [eventListeners, socket])

  return <SocketContext.Provider value={{ socket, notifications, updateAuthToken }}>{children}</SocketContext.Provider>
}

export const useSocketContext = (): SocketContextType => {
  const context = useContext(SocketContext)

  if (!context) {
    throw new Error('useSocketContext must be used within a SocketProvider')
  }

  return context
}
