/* eslint-disable consistent-return */
import { createReducer } from '@reduxjs/toolkit'
import { addTreeNode, setReportSetting, setWalletReports, updateReport, updateTree } from './actions'
import { ReportSettingType, TreeState } from './types'
import { findNodeById } from './helper'

const initialState: TreeState = {
  data: null,
  report: null,
  walletReport: null,
  reportSetting: null,
}

export default createReducer<TreeState>(initialState, (builder) =>
  builder
    .addCase(updateTree, (state, { payload }) => {
      return {
        ...state,
        data: payload,
      }
    })
    .addCase(addTreeNode, (state, { payload: { nodeId, children } }) => {
      if (!state.data) {
        return state // Return the current state if data is null
      }

      const parentNode = findNodeById(state?.data, nodeId)

      if (parentNode) {
        parentNode.children = children
      }
    })
    .addCase(updateReport, (state, { payload }) => {
      return {
        ...state,
        report: payload,
      }
    })
    .addCase(setWalletReports, (state, action) => {
      return {
        ...state,
        walletReport: action?.payload,
      }
    })
    .addCase(setReportSetting, (state, { payload }) => {
      return {
        ...state,
        reportSetting: {
          ...state?.reportSetting,
          ...payload,
        },
      }
    }),
)
