import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="33" height="32" viewBox="0 0 33 32" fill="none" {...props}>
      <path
        d="M24.458 8.61884C26.9878 12.28 28.2372 16.4097 27.7701 21.1639C27.7682 21.184 27.7575 21.2025 27.7407 21.2146C25.825 22.5993 23.9688 23.4396 22.1388 23.9969C22.1245 24.0011 22.1093 24.0009 22.0952 23.9962C22.081 23.9915 22.0688 23.9826 22.0601 23.9707C21.6373 23.3917 21.2531 22.7812 20.9166 22.1401C20.8972 22.1024 20.9149 22.0569 20.9546 22.042C21.5648 21.8157 22.1449 21.5445 22.703 21.2234C22.7469 21.1981 22.7497 21.1361 22.7091 21.1063C22.5907 21.0198 22.4734 20.9288 22.3611 20.8379C22.3401 20.8211 22.3118 20.8178 22.288 20.8291C18.6651 22.4758 14.6964 22.4758 11.0306 20.8291C11.0068 20.8186 10.9785 20.8222 10.9581 20.8387C10.8461 20.9297 10.7285 21.0198 10.6112 21.1063C10.5706 21.1361 10.5739 21.1981 10.6182 21.2234C11.1762 21.5385 11.7564 21.8157 12.3657 22.0431C12.4051 22.058 12.4239 22.1024 12.4043 22.1401C12.075 22.782 11.6909 23.3925 11.2602 23.9715C11.2415 23.995 11.2107 24.0057 11.1815 23.9969C9.36014 23.4396 7.50403 22.5993 5.58827 21.2146C5.57231 21.2025 5.56083 21.1832 5.55915 21.1631C5.16883 17.0508 5.96431 12.8869 8.86818 8.61801C8.87518 8.60671 8.88582 8.59789 8.89814 8.59265C10.327 7.94718 11.8577 7.47231 13.4577 7.20111C13.4868 7.1967 13.5159 7.20993 13.531 7.23529C13.7287 7.5798 13.9547 8.0216 14.1075 8.38264C15.794 8.12908 17.5067 8.12908 19.2284 8.38264C19.3813 8.02931 19.5994 7.5798 19.7963 7.23529C19.8033 7.22271 19.8142 7.21263 19.8273 7.20649C19.8405 7.20036 19.8553 7.19847 19.8696 7.20111C21.4704 7.47314 23.0012 7.94801 24.4289 8.59265C24.4415 8.59789 24.4518 8.60671 24.458 8.61884ZM14.9649 16.4378C14.9825 15.2221 14.0821 14.2161 12.9517 14.2161C11.8306 14.2161 10.9388 15.2133 10.9388 16.4378C10.9388 17.6621 11.8482 18.6592 12.9517 18.6592C14.0731 18.6592 14.9649 17.6621 14.9649 16.4378ZM22.4078 16.4378C22.4255 15.2221 21.525 14.2161 20.3949 14.2161C19.2735 14.2161 18.3817 15.2133 18.3817 16.4378C18.3817 17.6621 19.2912 18.6592 20.3949 18.6592C21.525 18.6592 22.4078 17.6621 22.4078 16.4378Z"
        fill="#FAFFFF"
      />
    </Svg>
  );
};

export default Icon;
