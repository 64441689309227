import { BASE_API_LIVE_SWAP } from 'config/constants/endpoints'
import { TokenType } from 'config/types/Wallet'
import fetchHelper from 'helpers/FetchHelper'

export interface WithdrawAdminDto {
  emailCode: string
  twoFactorAuthCode: string
  address: string
  wallet: '80_percent' | 'opv_balance' | 'market_develop'
  amount: number,
  token?: TokenType
}

const fetchWithdrawAdmin = (payload: WithdrawAdminDto): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      fetchHelper
        .fetch(`${BASE_API_LIVE_SWAP}/wallet/admin-withdraw`, {
          method: 'POST',
          body: JSON.stringify(payload),
        })
        .then(([resp, status]: any) => {
          if (status === 200 || status === 201) {
            resolve(resp)
          }

          throw resp
        })
    } catch (error) {
      reject(error)
    }
  })
}

export default fetchWithdrawAdmin
