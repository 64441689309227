import { Modal } from 'antd'
import styled from 'styled-components'
import { CloseIcon } from 'widgets/Svg'

const WrapperColse = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Wrapper = styled.div``

const BaseModalStyle = ({ children, ...props }) => {
  return (
    <Modal
      closeIcon={
        <WrapperColse>
          <CloseIcon />
        </WrapperColse>
      }
      {...props}
    >
      <Wrapper>{children}</Wrapper>
    </Modal>
  )
}

export default BaseModalStyle
