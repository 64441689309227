import { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Svg from '../Svg'

const Icon = ({ textCopy, ...props }: any) => {
  const [copySuccess, setCopySuccess] = useState(false)

  const onCopy = () => {
    if (!textCopy) return
    setCopySuccess(true)
    setTimeout(() => {
      setCopySuccess(false)
    }, 1000)
  }

  return (
    <CopyToClipboard onCopy={onCopy} text={textCopy}>
      {copySuccess ? (
        <Svg width="24" height="24" viewBox="0 0 32 32" {...props}>
          <path
            fill="#989996"
            d="M4 4v24h24V12.187l-2 2V26H6V6h19.813l2-2H4zm23.28 3.28L16 18.563l-4.28-4.28l-1.44 1.437l5 5l.72.686l.72-.687l12-12l-1.44-1.44z"
          />
        </Svg>
      ) : (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
          <path
            d="M17.25 6H3.75C3.55109 6 3.36032 6.07902 3.21967 6.21967C3.07902 6.36032 3 6.55109 3 6.75V20.25C3 20.4489 3.07902 20.6397 3.21967 20.7803C3.36032 20.921 3.55109 21 3.75 21H17.25C17.4489 21 17.6397 20.921 17.7803 20.7803C17.921 20.6397 18 20.4489 18 20.25V6.75C18 6.55109 17.921 6.36032 17.7803 6.21967C17.6397 6.07902 17.4489 6 17.25 6ZM16.5 19.5H4.5V7.5H16.5V19.5ZM21 3.75V17.25C21 17.4489 20.921 17.6397 20.7803 17.7803C20.6397 17.921 20.4489 18 20.25 18C20.0511 18 19.8603 17.921 19.7197 17.7803C19.579 17.6397 19.5 17.4489 19.5 17.25V4.5H6.75C6.55109 4.5 6.36032 4.42098 6.21967 4.28033C6.07902 4.13968 6 3.94891 6 3.75C6 3.55109 6.07902 3.36032 6.21967 3.21967C6.36032 3.07902 6.55109 3 6.75 3H20.25C20.4489 3 20.6397 3.07902 20.7803 3.21967C20.921 3.36032 21 3.55109 21 3.75Z"
            fill="#989996"
          />
        </Svg>
      )}
    </CopyToClipboard>
  )
}

export default Icon
