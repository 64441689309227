import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="33" height="32" viewBox="0 0 33 32" fill="none" {...props}>
      <path
        d="M13.1038 24C20.3499 24 24.3126 17.8456 24.3126 12.507C24.3126 12.3313 24.3082 12.1577 24.3016 11.9842C25.0708 11.4141 25.7389 10.702 26.2664 9.89296C25.5609 10.2152 24.8005 10.4315 24.0049 10.5285C24.8181 10.0282 25.4422 9.23718 25.7367 8.29521C24.9763 8.75718 24.1323 9.09521 23.2356 9.27549C22.517 8.49127 21.4928 8 20.3609 8C18.1851 8 16.4203 9.80958 16.4203 12.0383C16.4203 12.3561 16.4554 12.6625 16.5235 12.96C13.2488 12.791 10.3455 11.1842 8.40267 8.73915C8.06421 9.33634 7.8686 10.0304 7.8686 10.7696C7.8686 12.1713 8.56311 13.4085 9.62245 14.1318C8.9763 14.1115 8.3697 13.929 7.83783 13.627C7.83783 13.6428 7.83783 13.6608 7.83783 13.6789C7.83783 15.6349 9.19608 17.2687 10.9983 17.6383C10.6664 17.7307 10.3192 17.7803 9.96091 17.7803C9.70816 17.7803 9.45981 17.7555 9.22025 17.7082C9.72135 19.3127 11.1763 20.48 12.8994 20.5138C11.5499 21.5977 9.85322 22.2423 8.00707 22.2423C7.68838 22.2423 7.3763 22.2242 7.06641 22.1859C8.80926 23.3307 10.8796 24 13.1038 24Z"
        fill="#FAFFFF"
      />
    </Svg>
  );
};

export default Icon;
