import { createAction } from '@reduxjs/toolkit'
import { ReportSettingType, ReportType, TreeNode, WalletReportTypes } from './types'

export const updateTree = createAction<TreeNode>('tree/updateTree')

export const updateReport = createAction<ReportType>('tree/updateReport')

export const addTreeNode = createAction<{ nodeId: string; children: TreeNode[] }>('tree/addTreeNode')

export const setWalletReports = createAction<WalletReportTypes>('tree/setWalletReports')

export const setReportSetting = createAction<ReportSettingType, string>('tree/setReportSetting')
