import { BASE_API_LIVE_SWAP } from 'config/constants/endpoints'
import { NAME_NETWORK } from 'config/constants/networks'
import fetchHelper from 'helpers/FetchHelper'

function getWallet(payload: string, cbs?: any, cbe?: any) {
  try {
    fetchHelper
      .fetch(`${BASE_API_LIVE_SWAP}/wallet/deposit-address?token=${payload}&network=${NAME_NETWORK}`, {
        method: 'GET',
      })
      .then(([resp, status]: any) => {
        if (status === 200) {
          if (cbs) cbs(resp.data)
        } else if (cbe) cbe(resp)
      })
  } catch (error) {
    console.error('getWallet', error)
  }

  return null
}

export default getWallet
