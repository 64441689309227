/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-shadow */
import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import CustomFlagsSelector from 'components/CustomFlagsSelector'
import { LOCALIZATION_LIST } from 'config/constants'
import { useTranslation } from 'contexts/Localization'
import { languageList } from 'config/localization/languages'
import useGetLocation from 'hooks/useGetLocation'

const WrapSelector = styled.div`
  div {
    width: 170px;
    padding-bottom: 0;
  }

  #selected-country-btn {
    padding: 8px 24px;
    border: 1px solid rgba(250, 255, 255, 0.1);
    background: transparent;
    border-radius: 0;
  }

  ul {
    overflow: hidden;
    width: 170px;

    &:-webkit-scrollbar {
      display: none;
    }
  }
`

const LocalizeSelector = ({ ...props }) => {
  // localize state
  const [flag, setFlag] = useState(LOCALIZATION_LIST[0].flag)
  const { setLanguage } = useTranslation()
  const checkLocation = useGetLocation()

  useMemo(() => {
    const cacheLocalize = localStorage.getItem('localize')
    let _flag = LOCALIZATION_LIST[0].flag
    if (cacheLocalize) {
      const jsonLocalize = JSON.parse(cacheLocalize)
      _flag = jsonLocalize ? jsonLocalize.flag : LOCALIZATION_LIST[0].flag
    }
    setFlag(_flag)
  }, [])

  // update google translate selector
  const onChangeLocalize = (flag) => {
    const localize = LOCALIZATION_LIST.filter((x) => x.flag === flag)
    const localizeSec = document.getElementById('google_translate_element')
    if (localizeSec) {
      const localizeSelector = localizeSec.getElementsByTagName('select')[0]
      if (localizeSelector) {
        localStorage.setItem('localize', JSON.stringify(localize[0]))
        localizeSelector['value'] = localize[0].lang
        const event = new Event('change')
        localizeSelector.dispatchEvent(event)
        // dispatch only time not work (is researching)
        localizeSelector.dispatchEvent(event)
      }
    }
  }

  const handleChangeLang = useCallback(
    (flag) => {
      const selectedLang = languageList.find((item) => item.locale.toLowerCase().includes(flag.toLowerCase()))
      setLanguage(selectedLang)
    },
    [setLanguage],
  )

  const handleSelect = useCallback(
    (flag) => {
      setFlag(flag)
      onChangeLocalize(flag)
      handleChangeLang(flag)
    },
    [handleChangeLang],
  )

  useEffect(() => {
    if (checkLocation?.country_code === 'VN') {
      handleSelect('US')
      return
    }
    handleSelect('US')
  }, [checkLocation?.country_code, handleSelect])

  return (
    <WrapSelector {...props}>
      <CustomFlagsSelector
        countries={LOCALIZATION_LIST.map((x) => x.flag)}
        selected={flag}
        searchable={false}
        showSelectedLabel={!!true}
        customLabels={{ US: 'English', VN: 'Tiếng Việt', CN: 'Chinese' }}
        fullWidth={false}
        onSelect={(flag) => handleSelect(flag)}
        {...props}
      />
    </WrapSelector>
  )
}

export default LocalizeSelector
