import { Contract } from '@ethersproject/contracts'
import type { Signer } from '@ethersproject/abstract-signer'
import type { Provider } from '@ethersproject/providers'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import IPancakeRouter02ABI from 'config/abi/IPancakeRouter02.json'
import { IPancakeRouter02 } from 'config/abi/types/IPancakeRouter02'
import { CHAIN_ID } from 'config/constants/networks'
import { JSBI, Percent, Token, CurrencyAmount, Currency, ETHER } from '@pancakeswap/sdk'
import { TokenAddressMap } from 'state/types'

import { SFAddresses, ROUTER_ADDRESS } from '../config/constants/index'
import { BASE_BSC_SCAN_URLS } from '../config'
import { simpleRpcProvider } from './providers'

export * from './requestHelpers'
export * from './stringHelpers'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function getBscScanLink(
  data: string | number,
  type: 'transaction' | 'token' | 'address' | 'block' | 'countdown',
  chainIdOverride?: number,
): string {
  const chainId = chainIdOverride || CHAIN_ID
  switch (type) {
    case 'transaction': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/tx/${data}`
    }
    case 'token': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/token/${data}`
    }
    case 'block': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/block/${data}`
    }
    case 'countdown': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/block/countdown/${data}`
    }
    default: {
      return `${BASE_BSC_SCAN_URLS[chainId]}/address/${data}`
    }
  }
}

export function getBscScanLinkForNft(collectionAddress: string, tokenId: string): string {
  const chainId = CHAIN_ID
  return `${BASE_BSC_SCAN_URLS[chainId]}/token/${collectionAddress}?a=${tokenId}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, signer?: Signer | Provider): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, signer ?? simpleRpcProvider)
}

// account is optional
export function getRouterContract(_: number, library: Web3Provider, account?: string) {
  return getContract(ROUTER_ADDRESS[CHAIN_ID], IPancakeRouter02ABI, getProviderOrSigner(library, account))
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === ETHER) return true
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
}

// Round number
export const precise = (x) => {
  // eslint-disable-next-line no-restricted-globals
  if (x === undefined || isNaN(x)) return 0
  if (Math.abs(x) > 999) {
    return Math.round(x)
  }
  if (Math.abs(x) > 99) {
    return Math.round(10 * x) / 10
  }
  if (Math.abs(x) > 1.09) {
    return Math.round(100 * x) / 100
  }
  if (Math.abs(x) > 0.001) {
    return Math.round(10000 * x) / 10000
  }
  return +Number.parseFloat(x).toPrecision(2)
}

/* Utils - Calculate balance from value */
export const calculateBalance = (balance, decimal) => {
  if (balance && Math.abs(balance) > 0) {
    return precise(balance * 10 ** -decimal)
  }
  return 0
}
/* Utils - Calculate value from value */
export const calculateValue = (balance, price, decimal) => {
  if (balance && price && Math.abs(balance * price) > 0) {
    return calculateBalance(balance * price, decimal)
  }
  return 0
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

export function getSign(from, to, account) {
  return SFAddresses.includes((to as string).toLowerCase())
    ? '--'
    : to && !account?.toLowerCase()?.includes(to.toLowerCase())
    ? '-'
    : SFAddresses.includes((from as string).toLowerCase())
    ? '++'
    : to !== from
    ? '+'
    : ''
}

export function addCommasToNumber(number: number | string, decimalPlaces = 2): string {
  try {
    if (Number.isNaN(+number)) {
      throw new Error('Not a valid number')
    }

    // Nhân số với 10^decimalPlaces, sau đó làm tròn xuống bằng Math.floor
    const multiplier = 10 ** decimalPlaces
    const roundedNumber = Math.floor(+number * multiplier) / multiplier

    const numberWithCommas: string = roundedNumber.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    return numberWithCommas
  } catch {
    return '0'
  }
}

export function shortenHexString(hexString, length = 6) {
  if (hexString.length <= length + 4) {
    return hexString // If the string is already shorter than the desired length, return it as is
  }

  const prefix = hexString.slice(0, 2) // Extract the "0x" prefix
  const truncated = hexString.slice(2, length + 2) // Keep the first 'length' characters after the "0x" prefix
  const suffix = hexString.slice(-length) // Keep the last 'length' characters

  return `${prefix}${truncated}...${suffix}`
}

export const copyToClipBoard = (link: string) => {
  navigator.clipboard.writeText(link)
}

export const getRootUrl = (
  options = {
    withPathName: false,
  },
): string => {
  if (typeof window !== undefined) {
    if (options.withPathName) {
      return `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`
    }
    return `${window.location.protocol}//${window.location.hostname}`
  }
  return ''
}

// Function to check if a link belongs to the desired domain
export function isLinkFromDesiredDomain(link: string) {
  try {
    // Create a URL object to parse the link
    const url = new URL(link)

    // Extract the hostname from the URL
    const { origin } = url

    // Compare the hostname to the desired domain
    return origin === process.env.NEXT_PUBLIC_DOMAIN
  } catch (error) {
    // Handle invalid URLs or other errors
    console.error('Error:', error)
    return false
  }
}

export const convertTimeToISOString = (value: string | number, utcOffset = 0) => {
  if (value) return new Date(+value + utcOffset * 60 * 60 * 1000).toISOString()
  return ''
}

export function removeFalsyProperties<T>(inputObject: T): Partial<T> {
  const truthyObject: Partial<T> = {}

  // eslint-disable-next-line no-restricted-syntax
  for (const key in inputObject) {
    if (inputObject[key]) {
      truthyObject[key] = inputObject[key]
    }
  }

  return truthyObject
}

export function objectToQueryString<T extends object>(obj: T): string {
  try {
    const arr = Object.keys(obj)
      .filter((key) => obj[key] !== undefined)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)

    return `${arr?.length ? '?' : ''}${arr?.join('&')}`
  } catch (error) {
    return ''
  }
}

export function obfuscateEmail(email: string): string {
  // Kiểm tra email có tồn tại không
  if (!email) {
    return ''
  }

  // Tách phần trước và sau ký tự @ trong email
  const [username, domain] = email.split('@')

  // Tạo một chuỗi mới bằng cách thêm *** vào phần username
  const obfuscatedUsername = `***${username.substr(3)}`

  // Kết hợp lại email bằng cách nối phần username và domain
  const obfuscatedEmail = `${obfuscatedUsername}@${domain}`

  return obfuscatedEmail
}
