import { Box } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { Dropdown } from 'antd'
import { useTranslation } from 'contexts/Localization'
import { deleteAuthToken } from 'helpers/FetchHelper'
import useIsMobile from 'hooks/useIsMobile'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setModalConnect } from 'state/modal/actions'
import { updateUserInfo } from 'state/user/actions'
import { useUserData } from 'state/user/hooks/useUserData'
import { useGetUsdtWallet } from 'state/wallet/useGetWalletData'
import styled from 'styled-components'
import { Link } from 'widgets'
import BaseButton from 'widgets/BaseButton'
import { BellIcon } from '../../../packages/uikit/src/components/Svg'
import CollapseIcon from './CollapseIcon'
import DropdownUserMenu from './DropdownUserMenu'
import MobileSlider from './MobileSlider'
import { MenuEntry } from './types'

const WrapperHeader = styled.header<{ background: string }>`
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 88px;
  transition: 0.25s;
  z-index: 15;

  background: ${({ background }) => background};
  backdrop-filter: blur(30px);
  border-bottom: 1px solid rgba(250, 255, 255, 0.1);

  .content {
    position: relative;
    max-width: 1440px;
    padding: 0 16px;
    margin: 0 auto;
    width: 100%;

    display: flex;
    justify-content: space-between;

    ${({ theme }) => theme.mediaQueries.lg} {
      justify-content: space-around;
    }

    ${({ theme }) => theme.mediaQueries.xl} {
      padding: 0 32px;
    }

    .menu-left {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 44px;

        ${({ theme }) => theme.mediaQueries.md} {
          width: 55px;
        }
      }
    }

    .menu-list {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      padding: 0 16px;

      li {
        &:not(:last-child) {
          margin-right: 48px;
        }

        a {
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          display: block;

          &:hover {
            color: #adf700;
          }
        }

        &.active a {
          position: relative;
          font-weight: 600;
          color: #fdfffa;

          &:before {
            content: '';
            position: absolute;
            bottom: -5px;
            display: block;
            height: 2px;
            width: 50%;
            background: #adf700;
          }
        }
      }
    }

    .menu-right {
      display: flex;
      align-items: center;

      .notify-icon {
        width: 30px;
        position: relative;
        margin-right: 16px;
        cursor: pointer;

        span {
          position: absolute;
          top: 2px;
          right: -3px;
          width: 18px;
          height: 18px;
          background: #f41706;
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          width: 100%;
        }
      }

      .menu-actions-desktop {
        position: relative;
        display: flex;
        align-items: center;

        button {
          &:not(:last-child) {
            margin-right: 13px;
          }
        }
      }

      .auth-btn {
        min-width: 100px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.sign-in {
          background-color: transparent;
          background-image: url('/images/connect/sign-in-button-bg.png');
          background-repeat: no-repeat;
          background-size: cover;

          :hover {
            box-shadow: none;
          }
        }

        &.sign-up {
          margin-left: 24px;
          border-color: 2px solid #9bde00;

          :hover {
            background-color: transparent;
            background-image: url('/images/connect/sign-in-button-bg.png');
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }
  }
`
const MenuSubItem = styled.div`
  padding-top: 12px;
  ul {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    position: relative;
    padding: 20px;
    background: #eab962;
    list-style: none;

    &::before {
      content: '';

      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #eab962;
      z-index: 2;

      position: absolute;
      left: 24px;
      bottom: 100%;
    }
  }

  li {
    margin-right: 30px;

    a {
      color: #fff;
      font-weight: 500;
      font-size: 24px;
      line-height: 26px;
      letter-spacing: 0.04em;
      text-shadow: 1px 7px 4px rgba(111, 27, 47, 0.35), 0px -1px 0px #b64034;
      display: block;

      &:hover {
        color: #fff;
      }
    }

    &.active a {
      color: #fff;
    }
  }
`

const UserBox = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    margin-left: 32px;
    cursor: pointer;
  }

  svg {
    cursor: pointer;

    :hover {
      path {
        stroke: #adf700;
      }
    }
  }
`

interface Props {
  menuItems: MenuEntry[]
  hasSider?: boolean
}

const mobileWith = 991

const Header: React.FC<Props> = ({ menuItems, hasSider = false }) => {
  const { ethereum } = window
  const { t } = useTranslation()
  const router = useRouter()
  const [isScroll, updateIsScroll] = useState(false)

  const dispatch = useDispatch()
  const isMobile = useIsMobile(mobileWith)
  const { account } = useWeb3React()
  const userData = useUserData()
  const myWallet = useGetUsdtWallet()

  const [showSlider, setShowSlider] = useState(false)

  const toggleWallet = () => {
    dispatch(setModalConnect({ toggle: true }))
  }

  const toggleMenu = () => {
    setShowSlider((prev) => !prev)
  }

  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : undefined
  const paths = router.pathname.split('/').map((path) => `/${path}`)

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      updateIsScroll(true)
    } else {
      updateIsScroll(false)
    }
  }

  const handleSignOut = useCallback(() => {
    dispatch(updateUserInfo(undefined))
    const isDelete = deleteAuthToken()
    if (isDelete) {
      router.push('/sign-in')
    }
  }, [dispatch, router])

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground)
  })

  return (
    <>
      <WrapperHeader background={!isScroll ? '#0A14141a' : '#0A14141a'}>
        <div className="content">
          {!hasSider ? (
            <div className="menu-left">
              <Link href="/">
                <img src="/images/logo.png" alt="" />
              </Link>
            </div>
          ) : (
            <div />
          )}

          {!isMobile && (
            <ul className="menu-list">
              {menuItems.map((entry: any) => {
                const isActive = entry.actives.includes(paths[1])

                if (entry?.hide) return <></>

                if (entry.items) {
                  return (
                    <Dropdown
                      key={entry.label}
                      overlay={
                        <MenuSubItem>
                          <ul>
                            {entry.items.map((subMenu) => {
                              const subMenuActive = subMenu.actives.includes(paths[1])
                              return (
                                <li key={subMenu.label} className={subMenuActive ? 'active' : ''}>
                                  <Link href={subMenu.href} target={entry.target}>
                                    {subMenu.label}
                                  </Link>
                                </li>
                              )
                            })}
                          </ul>
                        </MenuSubItem>
                      }
                    >
                      <li key={entry.label} className={isActive ? 'active' : ''}>
                        <Link href={entry.href} target={entry.target}>
                          {entry.label}
                        </Link>
                      </li>
                    </Dropdown>
                  )
                }
                return (
                  <li key={entry.label} className={paths.includes(entry.href || '') ? 'active' : ''}>
                    <Link href={entry.href} target={entry.target}>
                      {t(entry.label)}
                    </Link>
                  </li>
                )
              })}
            </ul>
          )}

          <div className="menu-right">
            {isMobile ? (
              <CollapseIcon active={showSlider} toggleMenu={toggleMenu} />
            ) : (
              <div className="menu-actions-desktop">
                {/* <LocalizeSelector /> */}
                {/* <BaseButton className="open-app-btn" onClick={() => router.push('/connect')}>
                  <div>{accountEllipsis || 'Mở app'}</div>
                </BaseButton> */}
                {!userData ? (
                  <>
                    <BaseButton className="auth-btn sign-in" onClick={() => router.push('/sign-in')}>
                      {t('Sign in')}
                    </BaseButton>
                    <BaseButton className="auth-btn sign-up" secondary onClick={() => router.push('/sign-up')}>
                      {t('Sign up')}
                    </BaseButton>
                  </>
                ) : (
                  <UserBox id="area">
                    {/* <BellIcon onClick={() => router.push('/notification')} /> */}
                    <Dropdown
                      getPopupContainer={() => document.getElementById('area')}
                      overlay={
                        <DropdownUserMenu
                          userData={userData}
                          toggleMenu={toggleMenu}
                          onSignOut={handleSignOut}
                          myWallet={myWallet}
                        />
                      }
                      placement="bottomLeft"
                      trigger={['click']}
                      open={showSlider}
                      onOpenChange={toggleMenu}
                    >
                      <Box as="img" src={userData?.avatar} alt="" />
                    </Dropdown>
                  </UserBox>
                )}
              </div>
            )}
          </div>
        </div>
      </WrapperHeader>
      {isMobile && (
        <MobileSlider
          visible={showSlider}
          links={menuItems}
          accountEllipsis={accountEllipsis}
          toggleWallet={toggleWallet}
          toggleMenu={toggleMenu}
          userData={userData}
          onSignOut={handleSignOut}
          myWallet={myWallet}
        />
      )}
    </>
  )
}

export default Header
